import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['startTime', 'startDate', 'endDate', 'endTime', 'info', 'type', 'button']
  static values = {url: String, bookingId: String}

  check = () => {
    this.sendRequest()
  }

  typeTargetConnected(element) {
    if (this.hasStartDateTarget) {
      element.addEventListener('change', this.check)
    }
  }

  typeTargetDisconnected(element) {
    if (this.hasStartDateTarget) {
      this.check()
      element.removeEventListener('change', this.check)
    }
  }

  sendRequest() {
    let url = this.urlValue
    let params = {
      type_ids: Array.from(this.typeTargets).map((input) => input.value),
      start_date: this.startDateTarget.value,
      start_time: this.startTimeTarget.value,
      end_date: this.endDateTarget.value,
      end_time: this.endTimeTarget.value,
      booking_id: this.bookingIdValue
    }

    let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&')

    if (this.endDateTarget.value != '' && this.startDateTarget.value != '') {
      fetch(url + '?' + queryString)
        .then(response =>
          response.json()
        )
        .then((data) => {
          this.showInfo(data)
        })
    } else if (this.endDateTarget.value === '') {
      this.showInfo({'available': 'Bitte Enddatum auswählen'})
    }
  }

  showInfo(data) {
    const availability = data['available'];

    this.buttonTarget.disabled = availability;
    this.infoTarget.innerText = availability !== undefined ? availability : '';
    this.infoTarget.classList.toggle('d-none', availability === undefined);
  }
}
