import { Calendar } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import deLocale from '@fullcalendar/core/locales/de'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'

//CHECK WHICH CAL HAS TO BE RENDERED

$(document).on('turbo:load', function () {
  if ($('#time_table_id').length >= 1) {
    bookingCalendarInteractive()
  }
  //collapse header is clicked:
  $('#headingFour').click(function () {
    if ($('#time_table_id_type').length >= 1) {
      bookingTypeCalendar()
    } else if ($('#type_id').length >= 1) {
      bookingTypeCalendarWithoutTimeTable()
    }
  })
})

//CHECK WHICH CAL HAS TO BE RENDERED END

//SOME FUNCTIONS START

var bookings_json_url

function getFinalUrl(url_partial) {
  var url_string = window.location.href
  var url = new URL(url_string)

  if ($('#type_id').length >= 1) {
    var c = $('#type_id').html()
  } else {
    var c = $('#preselected_type_id').html() //url.searchParams.get("type");
  }

  if (window.location.port) {
    return (bookings_json_url =
      window.location.protocol +
      '//' +
      window.location.hostname +
      ':' +
      window.location.port +
      '/' +
      url_partial +
      '/' +
      c +
      '.json')
  } else {
    return (bookings_json_url =
      window.location.protocol +
      '//' +
      window.location.hostname +
      '/' +
      url_partial +
      '/' +
      c +
      '.json')
  }
}

function getTimeTableURL(tt_id) {
  var url_string = window.location.href
  var url = new URL(url_string)
  var tt_id = tt_id
  var tt_json_url
  if (window.location.port) {
    if (tt_id != null) {
      return (tt_json_url =
        window.location.protocol +
        '//' +
        window.location.hostname +
        ':' +
        window.location.port +
        '/time_tables/' +
        tt_id +
        '.json')
    } else {
      return (tt_json_url = window.location.href + '.json')
    }
  } else {
    if (tt_id != null) {
      return (tt_json_url =
        window.location.protocol +
        '//' +
        window.location.hostname +
        '/time_tables/' +
        tt_id +
        'json')
    } else {
      return (tt_json_url = window.location.href + '.json')
    }
  }
}
function add_zeros(hour) {
  if (hour === '') {
    return '0:00'
  } else {
    return hour + ':00'
  }
}

function update_inputs(event) {
  document.getElementById('calendarPickerStartDate').value =
    event.start.toLocaleString('de-DE')
  document.getElementById('calendarPickerEndDate').value =
    event.end.toLocaleString('de-DE')
  $('#current_time_selection').show()
  $('#date_picker_inputs').show()
}

function remove_selection(calendar, event) {
  var id = id || 'neu'
  event.remove()
  //only in edit mode
  if ($('#start_date').length) {
    var existing_event = calendar.getEventById(
      parseInt($('#booking_id').html())
    )
    existing_event.remove()
  }

  $('#date_picker_inputs').hide()
  addedEvent = false
}

var addedEvent = false
//SOME FUNCTIONS END

//calendar for type info with TIMETABLE
function bookingTypeCalendar() {
  getFinalUrl('type_bookings')
  $.getJSON(getTimeTableURL($('#time_table_id_type').html())).done(function (
    data
  ) {
    var time_table = data

    function slot(daynr, day, index) {
      if (time_table[day + '_start'][index] != undefined) {
        return {
          daysOfWeek: [daynr],
          startTime: add_zeros(time_table[day + '_start'][index]),
          endTime: add_zeros(time_table[day + '_end'][index]),
        }
      } else {
        return {
          daysOfWeek: [],
          startTime: '',
          endTime: '',
        }
      }
    }

    var calendarEl = document.getElementById('type_calendar')
    var calendar = new Calendar(calendarEl, {
      plugins: [timeGridPlugin, dayGridPlugin, listPlugin],
      initialView: window.innerWidth >= 765 ? 'timeGridWeek' : 'timeGridDay',
      locale: deLocale,
      eventColor: '#016973',
      fixedWeekCount: true,
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
      },
      buttonText: {
        listWeek: 'Liste',
      },
      events: bookings_json_url,
      themeSystem: 'standard',
      businessHours: [
        slot(1, 'mon', 0),
        slot(1, 'mon', 1),
        slot(1, 'mon', 2),
        slot(2, 'tue', 0),
        slot(2, 'tue', 1),
        slot(2, 'tue', 2),
        slot(3, 'wed', 0),
        slot(3, 'wed', 1),
        slot(3, 'wed', 2),
        slot(4, 'thu', 0),
        slot(4, 'thu', 1),
        slot(4, 'thu', 2),
        slot(5, 'fri', 0),
        slot(5, 'fri', 1),
        slot(5, 'fri', 2),
        slot(6, 'sat', 0),
        slot(6, 'sat', 1),
        slot(6, 'sat', 2),
        slot(7, 'sun', 0),
        slot(7, 'sun', 1),
        slot(7, 'sun', 2),
      ],
      selectConstraint: 'businessHours',
      eventConstraint: 'businessHours',
    })
    calendar.render()
  })
}

//END calendar for type info with TIMETABLE

//WITHOUT TIMETABLE

function bookingTypeCalendarWithoutTimeTable() {
  getFinalUrl('type_bookings')

  var calendarEl = document.getElementById('type_calendar')
  var calendar = new Calendar(calendarEl, {
    plugins: [timeGridPlugin, dayGridPlugin, listPlugin],
    initialView: window.innerWidth >= 765 ? 'timeGridWeek' : 'timeGridDay',
    locale: deLocale,
    eventColor: '#016973',
    fixedWeekCount: true,
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
    },
    buttonText: {
      listWeek: 'Liste',
    },
    events: bookings_json_url,
    themeSystem: 'standard',
  })
  calendar.render()
}
//END WITHOUT TIMETABLE

//START INTERACTIVE CAL IN BOOKING FORM

function bookingCalendarInteractive() {
  $('.cal_picker_loading').show()
  $('.cal_picker').hide()
  $('.cal_form').hide()

  getFinalUrl('types')
  //in type/show.json wird es gerendert..
  $.getJSON(getTimeTableURL($('#time_table_id').html())).done(function (data) {
    var time_table = data
    function slot(daynr, day, index) {
      if (time_table[day + '_start'][index] != undefined) {
        return {
          daysOfWeek: [daynr],
          startTime: add_zeros(time_table[day + '_start'][index]),
          endTime: add_zeros(time_table[day + '_end'][index]),
        }
      } else {
        return {
          daysOfWeek: [],
          startTime: '',
          endTime: '',
        }
      }
    }
    $.getJSON(bookings_json_url).done(function (data) {
      var newRecord = {
        id: parseInt($('#booking_id').html()),
        description: 'test mit kalender',
        title: $('#current_user_name').html(),
        start: $('#start_date').html(),
        end: $('#end_date').html(),
        display: 'auto',
      }
      function updateJSON(data, newRecord) {
        return data.map(function (item) {
          return item.id === newRecord.id &&
            item.start === newRecord.start &&
            item.end === newRecord.end &&
            item.display === newRecord.display
            ? newRecord
            : item
        })
      }
      var updated_booking_json = updateJSON(data, newRecord)
      var minDate =
        $('#start_date').html() !== undefined
          ? $('#start_date').html()
          : new Date()
      var selectOverlapAllowed = $('#select_overlap_allowed').text() === 'true'
      var calendarEl = document.getElementById('type_calendar_interactive')
      var calendar = new Calendar(calendarEl, {
        plugins: [timeGridPlugin, interactionPlugin],
        initialView: window.innerWidth >= 765 ? 'timeGridWeek' : 'timeGridDay',
        validRange: {
          start: minDate,
        },
        locale: deLocale,
        eventColor: '#016973',
        fixedWeekCount: true,
        nowIndicator: true,
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: '',
        },
        buttonText: {
          listWeek: 'Liste',
        },
        events: updated_booking_json,
        themeSystem: 'standard',
        snapDuration: '00:15:00',
        allDaySlot: false,
        editable: true,
        initialDate: $('#start_date').html(),
        selectOverlap: selectOverlapAllowed,
        selectable: true,
        selectAllow: function (info) {
          return info.start >= Date.now()
        },
        eventAllow: function (info) {
          return info.start >= Date.now()
        },
        businessHours: [
          slot(1, 'mon', 0),
          slot(1, 'mon', 1),
          slot(1, 'mon', 2),
          slot(2, 'tue', 0),
          slot(2, 'tue', 1),
          slot(2, 'tue', 2),
          slot(3, 'wed', 0),
          slot(3, 'wed', 1),
          slot(3, 'wed', 2),
          slot(4, 'thu', 0),
          slot(4, 'thu', 1),
          slot(4, 'thu', 2),
          slot(5, 'fri', 0),
          slot(5, 'fri', 1),
          slot(5, 'fri', 2),
          slot(6, 'sat', 0),
          slot(6, 'sat', 1),
          slot(6, 'sat', 2),
          slot(7, 'sun', 0),
          slot(7, 'sun', 1),
          slot(7, 'sun', 2),
        ],
        selectConstraint: 'businessHours',
        eventConstraint: 'businessHours',
        select: function (selectionInfo) {
          var new_event = calendar.getEventById('neu')
          if (addedEvent === true || $('#start_date').html() == 'undefined') {
            remove_selection(calendar, new_event)
          }
          var username = $('#current_user_name').html()
          var event = new Object()
          addedEvent = true
          event.id = 'neu'
          event.title = username
          event.start = selectionInfo.start
          event.end = selectionInfo.end
          event.allDay = false
          update_inputs(event)
          calendar.addEvent(event)
        },
        eventResize: function (eventResizeInfo) {
          update_inputs(eventResizeInfo.event)
        },
        eventDrop: function (eventDropInfo) {
          update_inputs(eventDropInfo.event)
        },
      })
      if ($('#booking_id').html() !== undefined) {
        //in edit mode: don't render event in background
        calendar
          .getEventById(parseInt($('#booking_id').html()))
          .setProp('display', 'auto')
        calendar
          .getEventById(parseInt($('#booking_id').html()))
          .setProp('title', $('#current_user_name').html())
      }
      $('.cal_picker_loading').hide()
      $('.cal_picker').show()
      $('.cal_form').show()

      calendar.render()
    })
  })
}

//END INTERACTIVE CAL IN BOOKING FORM
