import { Controller } from "@hotwired/stimulus";
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from '@fullcalendar/list'
import deLocale from '@fullcalendar/core/locales/de'

export default class extends Controller {
  static values = {
    events: Array,
  };

  connect() {
    console.log(this.eventsValue)

    this.calendar = new Calendar(this.element, {
      plugins: [dayGridPlugin, timeGridPlugin, listPlugin],
      initialView: "dayGridMonth",
      events: this.eventsValue,
      locale: deLocale,
      eventColor: '#016973',
      fixedWeekCount: true,
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
      },
      themeSystem: 'standard',

    });

    this.calendar.render();
  }

  disconnect() {
    this.calendar.destroy();
  }
}
