import {Controller} from '@hotwired/stimulus'
import {German} from 'flatpickr/dist/l10n/de'

export default class extends Controller {
  static targets = ['start', 'startTime', 'end']
  static values = {
    withTime: Boolean,
    format: String,
    startDate: String,
    endDate: String,
    types: String,
    minDate: String,
  }

  connect() {
    if (this.formatValue) {
      this.options = {dateFormat: this.formatValue}
    }

    this.startInstance = this.initializeDatePicker(this.startTarget, { minDate: this.minDateValue, ...this.options })

    if (this.hasEndTarget) {
      this.endInstance = this.initializeDatePicker(this.endTarget, { minDate: this.startInstance.selectedDates[0], ...this.options })
    }
    if (this.hasStartTimeTarget) {
      this.fetchOpeningTimes()
    }

    if (this.typesValue) {
      this.fetchDisabledDates()
      this.startInstance.config.onMonthChange.push(this.fetchDisabledDates)
    }
  }

  initializeDatePicker(element, options) {
    return flatpickr(element, {
      locale: German,
      altInput: true,
      enableTime: false,
      noCalendar: false,
      altFormat: 'D, j. F Y',
      dateFormat: 'd.m.Y',
      time_24hr: true,
      minuteIncrement: 15,
      disableMobile: true,
      ...options,
    })
  }

  toggle(event) {
    event.target.closest('.input-group').querySelector('.form-control')._flatpickr.toggle()
  }

  updateEndDate() {
    this.endInstance.set('minDate', this.startInstance.selectedDates[0])
  }

  fetchDisabledDates = () => {
    fetch(
      '/unavailable_dates/?current_month=' +
      (this.startInstance.currentMonth + 1) +
      '&current_year=' +
      this.startInstance.currentYear +
      '&type_id=' +
      this.typesValue
    )
      .then((response) => response.json())
      .then((data) => this.setDisabledDates(data, this.startInstance))
  }

  fetchOpeningTimes = () => {
    fetch(
      '/start_time/?start_date=' +
      this.startInstance.selectedDates[0] +
      '&type_id=' +
      this.typesValue
    )
      .then((response) => response.json())
      .then((data) => this.setTimeRange(data, this.startTimeTarget))
  }

  setTimeRange(data, target) {
    let options = {
      defaultHour: target.value,
      minTime: data.start_hour + ':' + data.start_minute,
      maxTime: data.end_hour + ':' + data.end_minute,
      enableTime: true,
      noCalendar: true,
      altFormat: 'H:i',
      dateFormat: 'H:i',
    }
    this.startTimeInstance = this.initializeDatePicker(target, options)
  }

  setDisabledDates(data, instance) {
    instance.set('disable', data.unavailable_dates)
  }
}
