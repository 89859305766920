import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { formId: String }

  connect() {
    this.element.addEventListener('change', this.submit)
    this.element.addEventListener('keyup', this.delay)
  }

  submit = () => {
    var form = null;
    if (this.formIdValue) {
      form = document.getElementById(this.formIdValue)
    } else {
      form = this.element.closest('form')
    }

    if (form) {
      form.requestSubmit()
    }
  }

  delay = () => {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(this.submit, 1000)
  }
}
